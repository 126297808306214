// modifer: styles/jss/nextjs-material-kit-pro/pages/aboutUsStyle.js
import { container, title, whiteColor } from 'styles/jss/original/base';

const styles = {
  container: {
    ...container,
    zIndex: 1
  },
  title: {
    ...title,
    marginBottom: '15px',
    color: whiteColor,
    '@media (max-width: 640px)': {
      fontSize: '1.8rem'
    }
  },
  titleSub: {
    color: whiteColor,
    marginBottom: '0',
    '@media (max-width: 640px)': {
      fontSize: '0.875rem'
    }
  },
  titleLevel1: {
    ...title,
    color: whiteColor,
    fontSize: '3rem',
    '@media (max-width: 640px)': {
      fontSize: '1.8rem'
    },
    '@media (max-width: 360px)': {
      fontSize: '1.5rem'
    }
  },
  textCenter: {
    textAlign: 'center'
  }
};

export default styles;
