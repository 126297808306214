// modifer: styles/jss/nextjs-material-kit-pro/pages/sectionsSections/teamsStyle
import { section, container, title } from 'styles/jss/original/base';

const styles = {
  section,
  container,
  title,
  textCenter: {
    textAlign: 'center'
  }
};

export default styles;
