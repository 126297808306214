import React from 'react';
import GridContainer from '@/components/material-ui-kit/components/Grid/GridContainer';
import GridItem from '@/components/material-ui-kit/components/Grid/GridItem';
import Parallax from '@/components/common/ui/Parallax';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'styles/jss/original/components/common/pageHeader';
import classNames from 'classnames';
import MainVisualHome from 'public/img/contents/main-visual-home@2x.jpg';
import MainVisualHomeSp from 'public/img/contents/main-visual-home.jpg';
import MainVisualMenu from 'public/img/contents/main-visual-menu@2x.jpg';
import MainVisualMenuSp from 'public/img/contents/main-visual-menu.jpg';
import MainVisualMenuCooking from 'public/img/contents/main-visual-menu-cooking@2x.jpg';
import MainVisualMenuCookingSp from 'public/img/contents/main-visual-menu-cooking.jpg';
import MainVisualMenuNoneCooking from 'public/img/contents/main-visual-menu-none@2x.jpg';
import MainVisualMenuNoneCookingSp from 'public/img/contents/main-visual-menu-none.jpg';
import MainVisualIdea from 'public/img/contents/main-visual-idea@2x.jpg';
import MainVisualIdeaSp from 'public/img/contents/main-visual-idea.jpg';
import MainVisualShopping from 'public/img/contents/main-visual-shopping@2x.jpg';
import MainVisualShoppingSp from 'public/img/contents/main-visual-shopping.jpg';
import MainVisualAbout from 'public/img/contents/main-visual-about@2x.jpg';
import MainVisualAboutSp from 'public/img/contents/main-visual-about.jpg';

// @ts-expect-error
const useStyles = makeStyles(styles);

type ComponentProps = {
  type:
    | 'home'
    | 'menu'
    | 'menuCategoryCooking'
    | 'menuCategoryNoneCooking'
    | 'idea'
    | 'shopping'
    | 'shoppingDetail'
    | 'about';
};

const pageHeaderList = [
  {
    titleMain: '狭いキッチンでつくる\n美しい食事習慣',
    titleSub: '「狭い」に特化した献立メディアだから\n本当に実践できる献立が見つかる',
    image: MainVisualHome,
    imageSp: MainVisualHomeSp,
    type: 'home'
  },
  {
    titleMain: '献立',
    titleSub: 'MENU',
    image: MainVisualMenu,
    imageSp: MainVisualMenuSp,
    type: 'menu'
  },
  {
    titleMain: '料理する人向け献立',
    titleSub: 'MENU',
    image: MainVisualMenuCooking,
    imageSp: MainVisualMenuCookingSp,
    type: 'menuCategoryCooking'
  },
  {
    titleMain: '料理しない人向け献立',
    titleSub: 'MENU',
    image: MainVisualMenuNoneCooking,
    imageSp: MainVisualMenuNoneCookingSp,
    type: 'menuCategoryNoneCooking'
  },
  {
    titleMain: 'アイデア',
    titleSub: 'IDEA',
    image: MainVisualIdea,
    imageSp: MainVisualIdeaSp,
    type: 'idea'
  },
  {
    titleMain: 'ショッピング',
    titleSub: 'SHOPPING',
    image: MainVisualShopping,
    imageSp: MainVisualShoppingSp,
    type: 'shopping'
  },
  {
    image: MainVisualShopping,
    imageSp: MainVisualShoppingSp,
    type: 'shoppingDetail'
  },
  {
    titleMain: 'メディア紹介',
    titleSub: 'ABOUT',
    image: MainVisualAbout,
    imageSp: MainVisualAboutSp,
    type: 'about'
  }
];

const PageHeader: React.FC<ComponentProps> = ({ type }) => {
  const classes = useStyles();

  return (
    <div>
      {pageHeaderList
        .filter((item) => item.type === type)
        .map((item) => {
          return (
            <Parallax image={item.image} filter='dark' small key={item.type}>
              <div className={classes.container}>
                {/* @ts-expect-error プロパティ「justify」は、タイプ「IntrinsicAttributes & Pick<InferProps<{ children.Requireable<ReactNodeLike>; className」に存在しません。*/}
                <GridContainer justify='center'>
                  {/* @ts-expect-error */}
                  <GridItem md={10} sm={8} className={classes.textCenter}>
                    {item.titleMain && item.type === 'home' ? (
                      <h2 className={classNames(classes.title, classes.titleLevel1)}>
                        {item.titleMain.split('\n').map((str, index) => (
                          <React.Fragment key={index}>
                            {str}
                            <br />
                          </React.Fragment>
                        ))}
                      </h2>
                    ) : item.titleMain ? (
                      <h1 className={classes.title}>
                        {item.titleMain.split('\n').map((str, index) => (
                          <React.Fragment key={index}>
                            {str}
                            <br />
                          </React.Fragment>
                        ))}
                      </h1>
                    ) : null}
                    {item.titleSub ? (
                      <h4 className={classes.titleSub}>
                        {item.titleSub.split('\n').map((str, index) => (
                          <React.Fragment key={index}>
                            {str}
                            <br />
                          </React.Fragment>
                        ))}
                      </h4>
                    ) : null}
                  </GridItem>
                </GridContainer>
              </div>
            </Parallax>
          );
        })}
    </div>
  );
};

export default PageHeader;
