// modifer: styles/jss/nextjs-material-kit-pro/pages/sectionsSections/teamsStyle
import {
  section,
  container,
  title,
  cardTitle,
  description,
  coloredShadow
} from 'styles/jss/original/base';

import tooltipsStyle from 'styles/jss/nextjs-material-kit-pro/tooltipsStyle.js';

const styles = {
  section,
  container,
  title,
  cardTitle: {
    ...cardTitle,
    '@media (max-width: 576px)': {
      marginTop: '0px !important'
    }
  },
  coloredShadow,
  description: {
    ...description,
    '@media (max-width: 576px)': {
      display: 'none'
    }
  },
  ...tooltipsStyle,
  card: {
    textAlign: 'left',
    '@media (max-width: 576px)': {
      marginTop: '20px',
      marginBottom: '0'
    }
  },
  cardCategory: {
    marginTop: '0',
    '@media (max-width: 640px)': {
      marginTop: '10px'
    }
  },
  number: {
    position: 'absolute',
    right: '-10px',
    top: '-16px',
    fontSize: '1rem',
    backgroundColor: '#555',
    color: '#fff',
    borderRadius: '50%',
    zIndex: '10',
    textAlign: 'center',
    width: '36px',
    height: '36px',

    '& span': {
      transform: 'translateY(25%)',
      display: 'inline-block'
    }
  },
  cardBody: {
    padding: '0.625rem 0'
  },
  cardFooter: {
    justifyContent: 'flex-end',
    paddingLeft: '0',
    paddingRight: '0',

    '& svg': {
      width: '24px',
      height: '24px'
    }
  },
  textCenter: {
    textAlign: 'center'
  }
};

export default styles;
