import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMedia } from 'react-use';
import { updateUser } from '@/actions/user';
import IdeaListItem from '@/components/ideas/IdeaListItem';
import useUser from '@/hooks/useUser';
import { useUiStatus } from '@/hooks/useUiStatus';
import { favoritePost } from '@/domains/firestore/user';
import { IIdea } from '@/@types/generated/contentful';

type ComponentProps = {
  idea: IIdea;
  showIndex: boolean;
  index: number;
};

const IdeaListItemContainer: React.FC<ComponentProps> = ({ idea, showIndex, index }) => {
  const [isLoading, setLoading] = useState(false);
  const { setModalState } = useUiStatus();
  const dispatch = useDispatch();
  const isSp = useMedia('(max-width: 640px)');
  const user = useUser();
  const isFavorite = !!user?.favorited?.includes(idea.fields.id);
  const handleRequestUpdateFavorite = useCallback(
    async (postId: string) => {
      if (!user) {
        setModalState(true);
      } else {
        setLoading(true);
        await favoritePost({ userId: user.uid, postId });
        await dispatch(updateUser(user.uid));
        setLoading(false);
      }
    },
    [dispatch, setModalState, user]
  );

  return (
    <IdeaListItem
      idea={idea}
      showIndex={showIndex}
      index={index}
      onRequestUpdateFavorite={handleRequestUpdateFavorite}
      isFavorite={isFavorite}
      isSp={isSp}
      invalid={isLoading}
    />
  );
};

export default IdeaListItemContainer;
