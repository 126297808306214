import React, { useCallback, useState, useEffect } from 'react';
import Link from 'next/link';
import { makeStyles } from '@material-ui/core/styles';
import Favorite from '@material-ui/icons/Favorite';
import GridContainer from '@/components/material-ui-kit/components/Grid/GridContainer';
import GridItem from '@/components/material-ui-kit/components/Grid/GridItem';
import Card from '@/components/material-ui-kit/components/Card/Card';
import CardHeader from '@/components/material-ui-kit/components/Card/CardHeader';
import CardBody from '@/components/material-ui-kit/components/Card/CardBody';
import CardFooter from '@/components/material-ui-kit/components/Card/CardFooter';
import Button from '@/components/material-ui-kit/components/CustomButtons/Button';
import Muted from '@/components/material-ui-kit/components/Typography/Muted';
import Tooltip from '@material-ui/core/Tooltip';
import styles from 'styles/jss/original/components/ideas/ideaListItem.js';
import LazyLoadImage from '@/components/common/ui/LazyloadImage';
import trancate from '@/lib/trancate';
import { IIdea } from '@/@types/generated/contentful';

const IMAGE_HEIGHT_PC = 210;
const IMAGE_HEIGHT_SP = 180;

// @ts-expect-error
const useStyles = makeStyles(styles);

type ComponentProps = {
  idea: IIdea;
  showIndex: boolean;
  onRequestUpdateFavorite: (id: string) => void;
  isFavorite: boolean;
  index: number;
  isSp: boolean;
  invalid: boolean;
};

const IdeaListItem: React.FC<ComponentProps> = ({
  idea,
  showIndex,
  index,
  onRequestUpdateFavorite,
  isFavorite,
  isSp,
  invalid
}) => {
  const classes = useStyles();
  const [imageHeight, setImageHeight] = useState(IMAGE_HEIGHT_PC);
  useEffect(() => {
    if (isSp) setImageHeight(IMAGE_HEIGHT_SP);
  }, [isSp]);
  const handleClick = useCallback(
    (id) => {
      onRequestUpdateFavorite(id);
    },
    [onRequestUpdateFavorite]
  );

  return (
    // @ts-expect-error
    <GridItem xs={6} sm={6} md={6}>
      <Card profile plain className={classes.card}>
        <GridContainer>
          {/* @ts-expect-error */}
          <GridItem xs={12} sm={5} md={5}>
            <CardHeader image plain>
              <Link href={`/ideas/${idea.fields.slug}`}>
                <a>
                  {showIndex && (
                    <p className={classes.number}>
                      <span>{index + 1}</span>
                    </p>
                  )}
                  <LazyLoadImage height={imageHeight}>
                    {(onLoad) => (
                      <picture>
                        <source
                          srcSet={`${idea.fields.thumbnail.fields.file.url}?fm=webp`}
                          media='(min-width: 641px)'
                          onLoad={onLoad}
                        />
                        <source
                          srcSet={`${idea.fields.thumbnail.fields.file.url}?fm=webp&fit=pad&w=400`}
                          media='(max-width: 640px)'
                          onLoad={onLoad}
                        />
                        <img
                          src={idea.fields.thumbnail.fields.file.url}
                          alt={idea.fields.title}
                          onLoad={onLoad}
                        />
                      </picture>
                    )}
                  </LazyLoadImage>
                  <div className={classes.coloredShadow} />
                </a>
              </Link>
            </CardHeader>
          </GridItem>
          {/* @ts-expect-error */}
          <GridItem xs={12} sm={7} md={7}>
            <CardBody plain className={classes.cardBody}>
              <Link href={`/ideas/${idea.fields.slug}`}>
                <a>
                  <Muted>
                    <h6 className={classes.cardCategory}>{idea.fields.cat}</h6>
                  </Muted>
                  <h4 className={classes.cardTitle}>{idea.fields.title}</h4>
                  <p className={classes.description}>{trancate(idea.fields.desc, 40)}</p>
                </a>
              </Link>
            </CardBody>
            <CardFooter profile plain className={classes.cardFooter}>
              <Tooltip
                id='tooltip-top'
                title='お気に入り'
                placement='top'
                classes={{ tooltip: classes.tooltip }}
              >
                {/* @ts-expect-error プロパティ 'children' はタイプ 'IntrinsicAttributes & RefAttributes<any>' に存在しません。*/}
                <Button
                  justIcon
                  simple
                  color={isFavorite ? 'warning' : 'white'}
                  link={!isFavorite}
                  disabled={invalid}
                  onClick={() => handleClick(idea.fields.id)}
                >
                  <Favorite />
                </Button>
              </Tooltip>
            </CardFooter>
          </GridItem>
        </GridContainer>
      </Card>
    </GridItem>
  );
};

export default IdeaListItem;
