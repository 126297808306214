import React from 'react';
import LazyLoad from 'react-lazyload';
import { compose, withStateHandlers, pure, StateHandler, StateHandlerMap } from 'recompose';
import { Transition } from 'react-transition-group';

type Outter = {
  children: (onLoad: StateHandler<State>, childStyle: { visibility?: string }) => React.ReactNode;
  height: number;
};

type State = {
  isLoaded: boolean;
};

interface Updaters extends StateHandlerMap<State> {
  onLoad: StateHandler<State>;
}

type Props = Outter & State & Updaters;

const LazyLoadImage = compose<Props, Outter>(
  withStateHandlers<State, Updaters, Outter>(
    {
      isLoaded: false
    },
    {
      onLoad: () => () => ({ isLoaded: true })
    }
  ),
  pure
)(({ children, height, isLoaded, onLoad }) => {
  const styles: Todo = {
    placeholder: {
      backgroundColor: '#F3F3F3',
      height
    },
    unLoadOuter: {
      opacity: 0.05,
      backgroundColor: '#000',
      height
    },
    loadedOuter: {
      opacity: 1,
      transition: 'opacity 1s ease-in'
    }
  };
  const childStyle = isLoaded ? {} : { visibility: 'hidden' };
  return (
    <LazyLoad offset={300} once placeholder={<div style={styles.placeholder} />}>
      <Transition in={isLoaded} timeout={1000}>
        {() => (
          <div style={isLoaded ? styles.loadedOuter : styles.unLoadOuter}>
            {children(onLoad, childStyle)}
          </div>
        )}
      </Transition>
    </LazyLoad>
  );
});

export default LazyLoadImage;
