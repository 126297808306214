import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'styles/jss/original/components/common/ui/parallaxStyle';

// @ts-expect-error
const useStyles = makeStyles(styles);

type ComponentProps = {
  className?: string;
  filter?: 'primary' | 'rose' | 'dark' | 'info' | 'success' | 'warning' | 'danger';
  children: React.ReactNode;
  style?: Todo;
  image: string;
  small?: boolean;
};

const Parallax: React.FC<ComponentProps> = (props) => {
  const windowScrollTop = 0;
  const [transform, setTransform] = useState('translate3d(0,' + windowScrollTop + 'px,0)');
  useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener('scroll', resetTransform);
    }
    return function cleanup() {
      if (window.innerWidth >= 768) {
        window.removeEventListener('scroll', resetTransform);
      }
    };
  }, []);
  const resetTransform = () => {
    const windowScrollTop = window.pageYOffset / 3;
    setTransform('translate3d(0,' + windowScrollTop + 'px,0)');
  };
  const {
    filter = 'primary',
    className = 'default',
    children,
    style,
    image,
    small = false
  } = props;
  const classes = useStyles();
  const parallaxClasses = classNames({
    [classes.parallax]: true,
    // @ts-expect-error
    [classes[filter + 'Color']]: filter !== undefined,
    [classes.small]: small,
    [className]: className !== undefined
  });
  return (
    <div
      className={parallaxClasses}
      style={{
        ...style,
        backgroundImage: 'url(' + image + ')',
        transform: transform
      }}
    >
      {children}
    </div>
  );
};

export default Parallax;
