export const MENU_FEATURE_COOKING_POPULUR = '料理する人に人気の献立';
export const MENU_FEATURE_NONE_COOKING_POPULUR = '料理しない人に人気の献立';
export const MENU_COOKING_LATEST = '料理する人向け新着献立';
export const MENU_NONE_COOKING_LATEST = '料理しない人向け新着献立';
export const MENU_SAME_CATEGORY = '同じカテゴリーの献立';
export const MENU_RELATION_SHOPPING = '献立に関連するアイテム';
export const MENU_SEARCH_TYPE = 'あなたのタイプで献立を探す';

export const IDEA_LATEST = '新着アイデア';
export const IDEA_INDEX = 'アイデアライブラリ';
export const IDEA_RELATION_SHOPPING = 'アイデアに関連するアイテム';

export const MENU_SEARCH_TAXONOMY_COOKING = '料理する人向け献立を探す';
export const MENU_SEARCH_TAXONOMY_NONE_COOKING = '料理しない人向け献立を探す';
export const MENU_TAXONOMY_COOKING = '料理する人向け';
export const MENU_TAXONOMY_NONE_COOKING = '料理しない人向け';

export const SHOPPING_FEATURE_RECOMMEND = '必ず欲しいおすすめアイテム';
export const SHOPPING_SAME_CATEGORY = '同じカテゴリーのアイテム';
export const SHOPPING_COMMENT_TIMELINE = 'アイテムへのコメント';
export const SHOPPING_COMMENT_POST = 'アイテムへコメントする';
export const SHOPPING_COMMENT_LOGIN = 'SNSアカウントでログイン';

export const GTM_CLICK_EVENT_CATEGORY = 'gtm-cat';
export const GTM_CLICK_EVENT_TITLE = 'gtm-title';
export const GTM_CLICK_EVENT_PRICE = 'gtm-price';

export const HEAD_MENU_TAXONOMY = 'HEAD_MENU_TAXONOMY';
export const HEAD_MENU_DETAIL = 'HEAD_MENU_DETAIL';
export const HEAD_IDEA_DETAIL = 'HEAD_IDEA_DETAIL';
export const HEAD_SHOPPING_DETAIL = 'HEAD_SHOPPING_DETAIL';
