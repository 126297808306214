import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from '@/components/material-ui-kit/components/Grid/GridContainer';
import styles from 'styles/jss/original/components/ideas/ideaList';
import IdeaListItemContainer from '@/containers/ideas/IdeaListItemContainer';
import { IIdea } from '@/@types/generated/contentful';

// @ts-expect-error
const useStyles = makeStyles(styles);

type ComponentProps = {
  title: string;
  ideaList: IIdea[];
  showIndex?: boolean;
};

const IdeaList: React.FC<ComponentProps> = ({ title, ideaList, showIndex = false }) => {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h3 className={classes.title}>{title}</h3>
        {ideaList.length === 0 ? (
          <p className={classes.textCenter}>アイデアはありません</p>
        ) : (
          <GridContainer>
            {ideaList.map((item, index) => {
              return (
                <IdeaListItemContainer
                  idea={item}
                  showIndex={showIndex}
                  index={index}
                  key={`idea-item-${index.toString()}`}
                />
              );
            })}
          </GridContainer>
        )}
      </div>
    </div>
  );
};

export default IdeaList;
